// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_sk d_gt d_cr";
export var heroHeaderCenter = "r_gv d_gv d_cr d_dv";
export var heroHeaderRight = "r_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "r_sl d_gq d_cv";
export var heroParagraphCenter = "r_gr d_gr d_cv d_dv";
export var heroParagraphRight = "r_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "r_sm d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "r_sn d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "r_sp d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "r_sq d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "r_sr d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "r_rV q_rV";
export var heroExceptionNormal = "r_rW q_rW";
export var heroExceptionLarge = "r_rX q_rX";
export var Title1Small = "r_rw q_rw q_q1 q_q2";
export var Title1Normal = "r_rx q_rx q_q1 q_q3";
export var Title1Large = "r_ry q_ry q_q1 q_q4";
export var BodySmall = "r_rL q_rL q_q1 q_rk";
export var BodyNormal = "r_rM q_rM q_q1 q_rl";
export var BodyLarge = "r_rN q_rN q_q1 q_rm";