// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "s_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "s_fQ d_fQ d_by d_bH";
export var menuDesign6 = "s_ss d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "s_st d_fL d_by d_dv d_bN d_bH";
export var menuRight = "s_sv d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "s_sw d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "s_sx d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "s_qB d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "s_qM d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "s_sy";
export var navbarItem = "s_qC d_bw";
export var navbarLogoItemWrapper = "s_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "s_sz d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "s_sB d_gc d_bx d_Y d_br";
export var burgerToggle = "s_sC d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "s_sD d_gc d_bx d_Y d_br";
export var burgerInput = "s_sF d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "s_sG d_f2 d_v d_G";
export var burgerLine = "s_sH d_f0";
export var burgerMenuDesign6 = "s_sJ d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "s_sK d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "s_sL d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "s_sM d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "s_sN d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "s_sP d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "s_sQ d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "s_sR d_bB d_bN";
export var staticBurger = "s_sS";
export var menu = "s_sT";
export var navbarDividedLogo = "s_sV";
export var nav = "s_sW";